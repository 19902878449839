// @flow
import React, { Fragment } from 'react';
import ContentText from 'Components/ContentText';
import Check from 'Components/Check';
import privacyModel from 'Stores/PrivacyModel';
import { deleteCookie } from 'Utils/index';
import { getGivenBackground } from 'Utils/getColor/index';
import CookieTable from './table';
import styles from './styles.scss';
import IntroText from 'Components/IntroText';
import Footnotes from 'Plugins/Footnotes';
import { useAnchor } from 'Utils/useAnchor';
import { withRouter } from 'react-router-dom';
import { InfoTextForGoogleCookies } from './InfoTextForGoogleCookies';

export const COOKIE_PERFORMANCE = 'performance';
export const COOKIE_KOMFORT = 'komfort';
export const COOKIE_MARKETING = 'marketing';

type State = {
  performance: boolean,
  komfort: boolean,
  marketing: boolean,
};

class CookieSettings extends React.Component<null, State> {
  constructor(props) {
    super(props);

    this.state = {
      performance: privacyModel.cookieTypes.performance,
      komfort: privacyModel.cookieTypes.komfort,
      marketing: privacyModel.cookieTypes.marketing,
    };
  }

  handleAnchor = useAnchor(this.props);

  componentDidMount() {
    document.addEventListener('click', this.handleAnchor);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleAnchor);
  }

  setCookieWasUpdated = () => this.props.setSharedState('cookieUpdated', true);

  handlePerformanceChange = (enabled: boolean) => {
    this.setCookieWasUpdated();
    privacyModel.updateCookie(COOKIE_PERFORMANCE, enabled);

    if (!enabled) {
      // remove all analytics cookies when disabled by the user
      deleteCookie('_ga');
      deleteCookie('_gat');
      deleteCookie('_gid');
    }

    this.setState({ performance: enabled });
  };

  handleKomfortChange = (enabled: boolean) => {
    this.setCookieWasUpdated();
    privacyModel.updateCookie(COOKIE_KOMFORT, enabled);

    this.setState({ komfort: enabled });
  };

  handleMarketingChange = (enabled: boolean) => {
    this.setCookieWasUpdated();
    privacyModel.updateCookie(COOKIE_MARKETING, enabled);

    this.setState({ marketing: enabled });
  };

  render() {
    const { performance, komfort, marketing } = this.state;

    return (
      <Fragment>
        <ContentText background={getGivenBackground('grey')}>
          <h4>Notwendig</h4>
          <br />
          <p>
            <a className={styles.anchor} href="#notwendig" data-anchor>
              Diese Cookies
            </a>{' '}
            helfen dabei, unsere Webseite nutzbar zu machen, indem sie zum Beispiel Grundfunktionen
            wie Seitennavigation und Zugriff auf sichere Bereiche der Webseite ermöglichen oder den
            Status Ihrer Cookie-Einstellungen speichern. Unsere Webseite kann ohne diese Cookies
            nicht richtig funktionieren. Der Einsatz von technisch erforderlichen Cookies und
            ähnlichen Technologien ist unbedingt erforderlich, um die von Ihnen aufgerufene Website
            ordnungsgemäß und sicher mit den für diesen Dienst typischen Funktionalitäten zur
            Verfügung zu stellen. Diese Technologien werden beispielsweise genutzt, um
          </p>
          <ul>
            <li>Grundfunktionen der Webseite zu ermöglichen,</li>
            <li>die Einstellung Ihrer Datenschutzpräferenzen zu speichern,</li>
            <li>das Ausfüllen von Formularen zu ermöglichen.</li>
          </ul>
        </ContentText>
        <ContentText background={getGivenBackground('white')}>
          <h4>Komfort</h4>
          <br />
          <p>
            Wir nutzen{' '}
            <a className={styles.anchor} href="#komfort" data-anchor>
              diese Cookies und andere Technologien
            </a>
            , um Ihnen die Bedienung der Seite zu erleichtern. Zum Beispiel wird der Standort des
            jeweiligen Händlers in einer Karte dargestellt.
          </p>
          <br />
          <br />

          <Check
            name="komfort"
            label={`${
              komfort
                ? 'Aktuell werden diese Cookies genutzt.'
                : 'Sie haben der Verwendung dieser Cookies widersprochen.'
            }`}
            checked={komfort}
            onChange={this.handleKomfortChange}
          />
        </ContentText>
        <ContentText background={getGivenBackground('grey')}>
          <h4>Statistik</h4>
          <br />
          <p>
            Um unser Angebot und unsere Webseite weiter zu verbessern, erfassen wir anonymisierte
            Daten für Statistiken und Analysen. Mithilfe{' '}
            <a className={styles.anchor} href="#statistik" data-anchor>
              dieser Cookies
            </a>{' '}
            können wir beispielsweise die Besucherzahlen und den Effekt bestimmter Seiten unseres
            Web-Auftritts ermitteln und unsere Inhalte optimieren.
          </p>
          <br />
          <br />
          <Check
            name="performance"
            label={`${
              performance
                ? 'Aktuell werden diese Cookies genutzt.'
                : 'Sie haben der Verwendung dieser Cookies widersprochen.'
            }`}
            checked={performance}
            onChange={this.handlePerformanceChange}
          />
        </ContentText>
        <ContentText background={getGivenBackground('white')}>
          <h4>Marketing</h4>
          <br />
          <p>
            <a className={styles.anchor} href="#marketing" data-anchor>
              Diese Cookies
            </a>{' '}
            werden genutzt, um Ihnen auf {window.dealer.domain} und auch anderen Websites und
            Plattformen personalisierte Inhalte und Werbekampagnen, passend zu Ihren Interessen,
            anzuzeigen.
          </p>
          <br />
          <br />

          <Check
            name="marketing"
            label={`${
              marketing
                ? 'Aktuell werden diese Cookies genutzt.'
                : 'Sie haben der Verwendung dieser Cookies widersprochen.'
            }`}
            checked={marketing}
            onChange={this.handleMarketingChange}
          />
        </ContentText>
        <IntroText
          title="Unsere Datenschutzrichtlinie"
          text="<p>Hier finden Sie unsere aktuelle Datenschutzerklärung sowie alle archivierten Versionen.</p>"
          cta1={{
            href: '/datenschutz/',
            label: 'Jetzt ansehen',
            type: 'secondary',
          }}
          background={getGivenBackground('grey')}
        />
        <CookieTable type="notwendig" anchor="notwendig" background={getGivenBackground('white')} />
        <ContentText background={getGivenBackground('grey')}>
          <h5>Des Weiteren setzen wir folgende Technologie ein:</h5>
          <br />
          <p>
            Google Tag Manager: Google Tag Manager ist eine Lösung von Google Ireland Limited,
            Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland ("Google"), mit der
            wir sog. Website-Tags über eine Oberfläche verwalten und so andere Dienste in unser
            Onlineangebot einbinden können. So kann der Google Tag Manager erkennen, ob für
            bestimmte Cookies eine Einwilligung vorliegt oder nicht und diese dann im Bedarfsfall
            aktivieren oder deaktivieren. Mit dem Google Tag Manager selbst, welches die Tags
            implementiert, werden noch keine Auswertungen vorgenommen, wie das Erstellen von
            Nutzerprofilen oder das Speichern von weiteren Cookies. Google erfährt nur die
            IP-Adresse des Nutzers, was notwendig ist, um den Google Tag Manager auszuführen und vom
            Nutzer gewünschte Einstellungen umzusetzen. Die IP-Adresse wird von Google anonymisiert.
            Weitere Informationen zum Datenschutz bei Google finden sich hier:{' '}
            <a href="https://marketingplatform.google.com">https://marketingplatform.google.com</a>{' '}
            ; Datenschutzerklärung:{' '}
            <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
          </p>
        </ContentText>
        <CookieTable type="komfort" anchor="komfort" background={getGivenBackground('white')} />
        <CookieTable type="statistik" anchor="statistik" background={getGivenBackground('grey')}>
          <InfoTextForGoogleCookies type="statistik" />
        </CookieTable>
        <CookieTable type="marketing" anchor="marketing" background={getGivenBackground('white')}>
          <InfoTextForGoogleCookies type="marketing" />
        </CookieTable>
        <Footnotes
          background={getGivenBackground('grey')}
          sharedState={this.props.sharedState}
          footnotes={[
            {
              id: 'C*',
              number: '*',
              text: `
                <p>
                  <em>First Party-Cookies</em> stammen vom Betreiber der Webseite.
                  Diese Cookies dienen dazu, den Besucher wieder zu erkennen und ermöglichen aussagekräftige
                  Berichte zur Nutzung der Webseite, diese den Nutzerbedürfnissen anzupassen und / oder Marketing-Strategien zu erarbeiten.
                  Diese Daten werden nicht domain-übergreifend zugänglich gemacht.
                </p>
              `,
            },
            {
              id: 'C**',
              number: '**',
              text: `
                <p>
                  <em>Third Party-Cookies</em> stammen von Werbetreibenden und werden nicht durch den Betreiber der Webseite gesetzt.
                  Diese Cookies ermöglichen die Wiedererkennung eines Webseiten-Besuchers, das Sammeln von Informationen für Werbung,
                  benutzerdefinierte Inhalte sowie Statistiken.
                </p>
              `,
            },
          ]}
        />
      </Fragment>
    );
  }
}

export default withRouter(CookieSettings);
