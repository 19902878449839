import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export default class Check extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    children: PropTypes.node,
    name: PropTypes.string,
    checked: PropTypes.bool,
    errorMessage: PropTypes.string,
    onChange: PropTypes.func,
    e2e: PropTypes.string,
    maxLength: PropTypes.string,
  };

  onChange = e => {
    this.props.onChange(e.target.checked, this.props.name);
  };

  render() {
    const { label, children, name, checked, errorMessage, e2e, maxLength } = this.props;

    const checkboxClass = cx('checkbox', {
      error: errorMessage,
    });

    return (
      <div className={checkboxClass}>
        <input
          id={`checkbox-${name}`}
          type="checkbox"
          name={name}
          defaultChecked={checked}
          onChange={this.onChange}
          data-e2e={e2e}
          maxlength={maxLength || 255}
        />
        <label htmlFor={`checkbox-${name}`} />
        <div className={styles.textWrapper}>{label || children}</div>
      </div>
    );
  }
}
